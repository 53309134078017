.quiz{
    /* position: absolute; */
    top: 89px;
}

.image{
    position: relative;
    width: 40%;
    height: auto;
   

}


