.reporting{
    position: relative;
    display: flex;
    top: 89px;
    /* top: calc(100% - 80px); */
    height: calc(100% - 89px);
    flex-direction: column; 
    width: 100%;
}



.content-container{
    margin-left: 15px;
    margin-right: 15px;
}

.box {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    border: 1px solid #000;
  }

  .custom-column {
    height: 20px; /* set the height to whatever you prefer */
    display: flex;
    align-items: center;
    justify-content: center; /* in case you also want to horizontally center the text */
  }

  .colored-bar {
    display: flex;
    align-items: stretch; 
    width: 100%;
    height: auto;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #F4F0F0;
}

.decorator {
    width: 5px;
    flex-shrink: 0;
    min-height: 40px; 
    /* height: 100%; */
}

.colored-bar .column.is-narrow {
  align-self: stretch; 
}


.map-wrap-main {
  position: relative; 
  /* left: 1%;
  top: 8vh;*/
  top:0;
  width: 700px;
  height: 338px; 
}

.map-main {
  position: absolute;
  width: 100%;
  height: 100%;
}


@media (min-width: 2400px) {

  .map-wrap-main {
      width: 98%;
      height: 98%; 
    }
}


/* Media Query for Larger Screens */
@media (min-width: 1600px) {
 
  
  .map-wrap-main {
    width: 98%;
    height: 98%; 
  }
}

.admin-text{
  font-size: 10px;
  
}


@media print {
  /* Hide elements that are not needed in the print version */
  .hide-on-print, button, .header, .footer {
    display: none;
  }

  /* Adjust layout for print */
  .reporting, .content-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  /* Add any additional print-specific styles here */
  /* For example, ensuring tables, charts, and maps are properly sized */
}


.reporting-analytics{
  max-height: 12rem;
  overflow-y: auto;
  overflow-x: hidden;
}




