.nav {
  position: fixed;;
  display: flex;
  flex-wrap: wrap; 
  background-color: #3464CC;
  height: 79px;
  width: 100%;
  top: 0;
  /* padding: 0px; */
  z-index: 300;
  /* opacity: 0.8;  */
}

.translucent-header{
  background-color: rgba(255, 255, 255, 0.2);
  position: fixed;;
  display: flex;
  flex-wrap: wrap; 
  height: 79px;
  width: 100%;
  top: 0;
  z-index: 300;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  
}

.advisory {
  background-color: #ffff;
  position: relative;
  left: 1%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='green' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-color: #17A756;
  fill: #17A756;
  color: darkblue;
  height: 72px;
  width: 303px;
  top: 4px;
  word-wrap: break-word;
  text-align: center;
  font-size: 16px;
  font-family: 'Arial';
  font-style: bold;
  font-weight: bolder;
}

.advisory-text{
  padding-top: 20px;
}

.logo {
  position: absolute;
  right: 1%;
  top: 4px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  gap: 1px;
}

.logo img {
  height: 100%; /* Adjusted to ensure logo height matches the container's height */
  width: auto; /* Set width to auto to maintain aspect ratio */
  max-height: 100%; 
  object-fit: contain; /* Keeps the aspect ratio while fitting to the container */
}
.logo img:last-child {
  width: auto; /* Adjust as necessary */
}
.logo img.last-logo {
  width: auto; /* Adjust as necessary */
}


@media (max-width: 768px) {
  .logo {
    flex-wrap: wrap;
    justify-content: center;
    top: 10px;
  }
}

.Menu {
  /* background-color: #3464CC; */
  position: relative;
  left: 2vh;
  top: 10px;
  width: 100%; /* Modified */
  max-width: 850px; /* Added */
  height: 72px;
  font-size: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MenuItems {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  /* max-width: 850px; */
  width: 100%;
  /* color: white; */
}

.MenuItems .navLink {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .MenuItems {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.activeLink {
  color: #17A756;
  font-weight: bold;
  text-decoration: none;
}

.MenuItems a:hover {
  color: #17A756;
}

/* Adjusting navbar-start position to the right */
.custom-navbar-start {
  margin-left: 1rem; /* Adjust this value as needed */
  /* color: white; */
}

/* For a responsive adjustment, you might want to only apply this on larger screens */
@media screen and (min-width: 769px) {
  .custom-navbar-start {
    margin-left: 11rem; /* Or any value that suits your layout */
    color: white;
  }
}




.MenuItems .navLink {
  text-decoration: none;
  color: inherit;
}

.activeLink {
  color: #17A756;
  font-weight: bold;
  text-decoration: none;
}

.MenuItems a:hover {
  color: #17A756;
}

/* Default navbar item color */
.navbar-item, .navLink {
  color: white !important;
  background-color: transparent; 
}

/* Active link color */
.activeLink {
  color: #17A756 !important; /* Or any other color you want for active links */
}


.navbar-item.navLink:hover {
  color: #17A756 !important; /* More specific, with !important */
}


/* Media Queries for Big Screen Sizes */
@media only screen and (min-width: 1800px) {

  .Menu{
    left: 15%;
  }
  .nav{
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .navbar-menu {
    flex-direction: column;
    align-items: center;
  }
  .navbar-start, .navbar-end {
    justify-content: center;
    width: 100%;
  }

  .navbar-item, .navLink {
    color: darkblue !important;
    background-color: transparent; 
  }
  .activeLink {
    color: #17A756 !important; /* Or any other color you want for active links */
  }
  

}




