.unavailable-page {
    text-align: center;
    margin-top: 100px;
  }
  
  .unavailable-page h1 {
    font-size: 24px;
    color: #333;
  }
  
  .unavailable-page p {
    font-size: 18px;
    color: #777;
  }
  