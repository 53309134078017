
/* @import 'bulma/css/bulma.css'; */
.map-wrap {
    position: absolute;
    left: 360px;
    top: 79px;
    height: calc(100% - 79px);
    width: calc(100% - 360px);
    cursor: pointer;

  }

  .map-chart{
    /* position: absolute; */
    height: auto;
    width: auto;
    right: 1%;
    top: 50%;
    background-color:white;
    /* z-index: 100; */
  }

 

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    cursor: pointer;
    background-color: red;
    color: white;
  }
  

  .map {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .maplibregl-ctrl-basemaps .basemap {
    left: 1%;
    width: 28px;
    height: 28px;
    margin: 2px;
    border: 2px solid #ccc;
    /* border-radius: 40px; */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }

  /* CSS to initially hide the export control options */
.export-control-options {
  display: none;
}

/* CSS to show the export control options when the icon is clicked */
.export-control-options.expanded {
  top:30px;
  display: block;
}

/* Your own stylesheet to enforce the initial state */

/* Ensures the export list is hidden when the page loads */
/* .maplibregl-export-list {
  display: none !important;
} */



/* You can add styles for when the export list is active if needed */
.maplibregl-export-list.active {
  top: 10px;
  display: block !important;
}

.geocoding {
  position: absolute;
  top: 0;
  height: 10px;
  left: 10px;
  /* z-index: 1000;   */
}

