.RightBarContainer {
  position: absolute;
  right: 1%;
  top: 100px;
  background: #FFFF;
  width: 350px;
  /* max-height: 500px; */
  z-index: 100;
}

#tabContainer {
  display: flex;
  justify-content: space-around;
  background-color: #eee;
  padding: 10px 0;
  color: black;
  font-family: Arial;
  font-size: 14px;
}

#contentContainer {
  max-height: 450px;
  overflow-y: auto;
}

/* Media Queries */
@media (max-width: 768px) {
  .RightBarContainer {
    width: 200px;
    max-height: 400px;
  }
}

  /* .title{
    color: black;
    font-family: Arial;
    font-size: 12px;
  } */