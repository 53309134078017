
.side-panel{
background: #FFFF;
position: relative;
width: 360px;
height: calc(100vh - 79px);
top: 79px;
border:0.5px solid #000000;
box-shadow: 1px 2px 2px #888888;

overflow-y: auto;
overflow-x: hidden;
display: flex;
flex-direction: column; /* Stack children vertically */
padding: 20px;

}

.Names{
    position: absolute; /* Remove absolute positioning */
    margin-bottom: 10px;
    display: inline-flex;
    width: 79px;
    height: 13px;
    column-gap: 123px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    
    color:darkblue;
    font-family: Arial;
    font-style: bold;
    color: blacks;
    font-weight: bolder;
    align-items: left;
    text-align: left;
    margin-left: 4%;
    margin-top: 10px;
    
   
}

.select-country {
    position: relative;
    display: inline-flex;
    top: 40px;
    left: 8%;
    width: auto !important;
    height: 10px;
    border-radius: 15px !important;
    align-items: center;
	text-align: center;
    font-size: 12px;
    font-family: Arial;
    font-style: bold;
    color: black;
    font-weight: bolder;

}

.admin-boundary-switch {
    position: static; /* Remove absolute positioning */
    display: block; /* Align with the flexbox layout */
    margin-bottom: -4vh; 
    margin-top: 4vh;

  }

.admin-text{
    position: static;
    left: 0;
    margin-bottom: 2px;
    white-space: nowrap;
    
}

.analysis-info{
  position: relative;
  height: auto;
  width: 220px;
  z-index: 100;
  top: 20%;
  left: 30%;
  background-color: white;  
  justify-content: center ;
  align-items: center;
  font-family: poppins;

 }

 .data-params{
  font-family: poppins;
  font-size: 8px;
  color: #3364CC;
  /* font-weight: 600; */
 }

.select-controls__control{
    width: auto !important; 
    height: 4%;
    border-radius: 25px !important;
    text-align: left;
    margin-bottom: 2px;
}






.select-region{
    position: absolute;
    display: inline-flex;
    top: 40px;
    left: 3%;
    width: auto;
    /* height: 10px; */
    /* border-radius: 20px !important; */
    align-items: center;
	text-align: center;
    font-family: Arial;
    font-style: bold;
    font-size: 12px;
    color: black;
    font-weight: bolder;
    align-items: left;
    text-align: left;

}


.vertical-line {
    border-left: 1px solid black;
    height: 110%;
    z-index: 500;
    position: absolute;
    left: 90%;
  }

/* e48qrgd9 */


.switch_admin {
    position: absolute;
    display: inline-block;
    left: 3%;
    margin: auto;
    
    width: 48px;
    height: 24px;
  }
  
  
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color:  #014487;
    ;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 0.5px #014487;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;}
  
    /* .chart-container {
      margin-bottom: 10px; 
    } */

    .charts-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0; 
      column-gap: 0px !important;
      /* max-height: 70vh; */
      overflow-x: hidden;
      overflow-y: auto;
      
    }