.combined-drought-indicator{
    position: absolute;
    display: block;
    width: 441px;
    /* height: 500px; */
    margin-top:12%;
    left: 3%;
    height: auto;
    margin-bottom: 60%;
   
}

.has-background-light {
  background-color: #F7F7F7; /* Light grey */
}

.has-background-white {
  background-color: #ffffff; /* White */
}
/* IndicatorsStyle.css or your custom stylesheet */

.modal-card-body .data-row {
  font-size: 0.85rem; /* Adjust the size as needed */
}

.wider-modal {
  width: 80%; /* Adjust the width as needed */
  max-width: 800px; /* Ensure it doesn't get too wide on larger screens */
}

.alternating-row-background {
  background-color: #f5f5f5;
}

/* Ensure the first child has a different background if needed */
.alternating-row-background:nth-child(odd) {
  background-color: #ffffff;
}


.Indicator-names{
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 15px;
    left: 40%;
    font-family: Arial;
    font-style: bold;
    font-size: 12px;
    color: darkblue;
    font-weight: bolder;
    align-items: left;
    text-align: left;
    margin-left: 5px;
    margin-top: 10px;
   
}

.info-subsection{
    position: absolute;
    margin-top: 10%;
    display: inline-flex;
    left: -5px;
    height: auto;
    width: auto;
    

}

.indicator-description{
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    margin-left:70px;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: black;
    
}

.info-icon{
  position: relative;
  left: 118%;
  margin-top: 8px;
  width: 22px;
  height: 17px;
}


.info-icon-m-cdi{
  position: relative;
  /* margin-left:10vh; */
  margin-top: 8px;
  width: 22px;
  height: 17px;
}

.cdi-selection{
  display: flex;
  /* margin-top: 10px; */
  margin-left: 4%;
  column-gap: 5%;
  /* row-gap: 50px; */
  width: auto;
  height: auto;
  /* margin-bottom: 10px; */
  padding-top: 0.001px;
  padding-bottom: 10px;
  

}

.select-controls-indicators__control{
  width: auto !important; 
  border-radius: 20px !important;

  
}

.border_bottom{
  position: relative;
  width: 441px;
  height: 2px;

}

.monthly-cdi{
  position: relative;
  /* margin-bottom: 30%; */
  margin-left: -15px;
  margin-top: 60px;
  margin-bottom: 10px;
}

.cdi-monthly-selection{
  position: relative;
  display: flex;
  column-gap: 5%;
  left:4%;
  /* margin-bottom: 50px; */
}

.sub-section-selection{
  position: relative;
  /* margin-bottom: 30%; */
  margin-left: -15px;
  margin-top: 60px;
  margin-bottom: 6px;
}

.landuse-sub-section-selection{
  position: relative;
  /* margin-bottom: 30%; */
  margin-left: -15px;
  /* margin-top: 60px; */
  margin-bottom: 10px;
}

/* precipitation module */

.precipitation-module{
  position: relative;
  margin-top: 3vh;
 height: auto;
}

.info-icon-monthly-precipitation{
  position: relative;
  /* margin-left: 4.85vh; */
  margin-top: 8px;
  width: 22px;
  height: 17px;

}

.info-icon-spi{
  position: absolute;
  left: 127%;
  margin-top: 8px;
  width: 22px;
  height: 17px;
}

/* Vegetation module */

.vegetation-module{
  position: relative;
  margin-top: 3vh;
  height: auto;
}

.info-icon-fapar-a{
  position: relative;
  /* margin-top: 0vh; */
  margin-top: 8px;
  left:87%;
  width: 22px;
  height: 17px;
}

.info-icon-monthly-ndvi{
  position: relative;
  left:7.5%;
  width: 22px;
  height: 17px;
}

.soil-module{
  position: relative;
  margin-top: 3vh;
  height: auto;
}

/* geogrphic background module */

.geography-bg-module{
  position: relative;
  margin-top: 3vh;
  height: auto;
}

/* protected areas module */

.protected-areas-module{
  position: relative;
  margin-top: 3vh;
  height: auto;
}

/* check box */
.switch {
  position: absolute;
  display: inline-block;
  left: 3%;
  margin: auto;
  
  width: 48px;
  height: 24px;
}



.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color:  #014487;
  ;
}

input:focus + .slider {
  box-shadow: 0 0 0.5px #014487;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;}



  /* In your IndicatorsStyle.css or another CSS file */

.spinner {
  border: 4px solid rgba(0,0,0,0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
