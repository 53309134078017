/* Custom styles that complement Bulma */

/* Adjust the hero section if needed */
.hero {
  background-size: cover;
  background-position: center;
  height: 10%;
}

.map-header {
  padding: 10px;
  text-align: left;
  font-size: 18px;
}

.map-container {
  border-right: 2px solid #d3d3d3; /* Adjust the color as needed */
  padding: 10px;
  margin-bottom: 20px;
}
.home-description{
  color: white;
}


.map-wrap-home {
  position: relative; 
  /* left: 1%;
  top: 8vh;*/
  top:0;
  width: 90%;
  height: 338px; 
  padding: 10px;
  box-sizing: border-box;
}

/* Make sure the map itself fills its container */
.map-main {
  width: 100%;
  height: 100%; /* You can set a specific height here if required */
}

.legend-image img {
  width: 10px; /* Adjust as needed */
  height: auto; /* Keeps the aspect ratio intact */
}

.legend-and-button {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 100%; 
 
}
/* Style for the geoportal button */
.geoportal-button {
  border: 2px solid #17A756; /* Outline color */
  background-color: transparent; /* Transparent background */
  color: #17A756; /* Text color */
  margin-right: 20px; /* Right margin */
  padding: 5px 10px; /* Reduced padding */
  font-size: 0.8rem; /* Reduced font size */
  /* Other styles... */
}

.geoportal-button:hover {
  background-color: #17A756; /* Background color on hover */
  color: white; /* Text color on hover */
  /* Other styles... */
}

.custom-text-box {
  padding: 1rem;
  background-color: #f5f5f5; /* Light grey background */
  border-radius: 5px;
  /* Add other styles as necessary */
}

.footer {
  background-color: #f5f5f5; /* Match the footer background color to your design */
  padding: 2rem 0;
}

.footer .title {
  color: #4a4a4a;
  margin-bottom: 0.5rem;
}

.footer-link {
  display: block;
  margin-bottom: 0.25rem; /* Spacing between links */
  color: #4a4a4a; /* Adjust the color to match your design */
}

.footer .button {
  background-color: transparent; 
  border-color: #17A756; /* Border color for the button */
  color: #17A756; /* Text color for the button */
}
.footer-titles{
  color: #17A756;
}

.footer .button:hover {
  background-color: #17A756; /* Background color for the button on hover */
  color: white; /* Text color for the button on hover */
}

.feedback-button {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  align-items: center; /* Center button vertically */
  padding-right: 1.5rem; /* Add padding to ensure button does not touch the edge */
}

.footer .footer-titles {
  color: #17A756; /* Set the titles color */
  margin-bottom: 0.5rem;
}



/* Styles for the footer links */
.footer-link {
  color: #4a4a4a; /* Normal state color */
  margin-bottom: 0.25rem; /* Spacing between links */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

/* Hover effect for footer links */
.footer-link:hover,
.footer-link:focus {
  color: #17A756; /* Color change on hover and focus */
  text-decoration: none; /* Optional: removes underline on hover */
}

/* Feedback Button */
.feedback-button .button {
  background-color: transparent; /* Transparent background */
  color: #17A756; /* Text color */
  border: 2px solid #17A756; /* Border color */
  border-radius: 290486px; /* Fully rounded borders */
}

/* Feedback Button on Hover */
.feedback-button .button:hover {
  background-color: #17A756; /* Background color on hover */
  color: white; /* Text color on hover */
}


@media screen and (max-width: 768px) {
  .has-text-right-desktop {
    text-align: left;
  }
  .feedback-button {
    padding-right: 0;
    justify-content: center; /* Center button on mobile */
  }
}

 

.button-custom-medium {
  background-color: #FFA600; /* Custom color for Medium button */
}

.button-custom-low {
  background-color: #fff645; /* Custom color for Low button */
}

.analytics-box{
  position: relative;
  right: 69%;
  width: 60%;
  height: 12rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.header-analytics{
  position:relative;
  right: 65%;
}

.typing-animation {
  /* The width should be set to the total width the text will eventually occupy. 
     This may need adjustment to precisely fit the text. */
  width: 0ch; /* Start with 0 and will expand to fit the text */
  white-space: nowrap;
  overflow: hidden;
  /* Adjust the duration as needed. The 'steps()' function should match the exact number of characters. */
  animation: typing 4s steps(13, end) forwards;
}

/* Typing animation */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; } /* Using 100% to ensure it covers the text length exactly */
}
